import { FC, useContext, useEffect, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import MEditor, { Monaco } from "@monaco-editor/react";
import { Screen } from "./helpers/Screen";
import { FileSystem } from "./helpers/FileSystem";
import { Auth } from "../../contexts/ridersUserContext";
import { setArduinoLanguage } from "../../components/vs-code/Arduino/Utils";

const LoadingScreen: FC = () => {
  return (
    <div style={{ color: "white" }}>
      <AiOutlineLoading
        style={{ width: "60px", height: "60px", color: "white" }}
      />
      <p>Editor is loading</p>
    </div>
  );
};

export const Editor: FC<{
  file_path: string;
  code: string | undefined;
  setCode: Function;
  setMount: Function;
  className: string;
}> = (props) => {
  const { file_path, setCode, setMount, className } = props;

  const { refreshKey } = useContext<any>(Screen.context);
  const { readFile } = useContext<any>(FileSystem.context);
  const { projectType, language, community } = useContext<any>(Auth.context);

  const isDark = document
    .getElementsByTagName("html")[0]
    .classList.contains("dark");

  const [userCode, setUserCode] = useState<string | undefined>(undefined);

  const editorBeforeMount = (monaco: Monaco) => {
    setArduinoLanguage(monaco);
  };

  useEffect(() => {
    readFile(file_path).then((code: string) => {
      setUserCode(code);
    });
  }, [refreshKey]);

  useEffect(() => {
    console.log("userCode", language);
  }, [language]);

  return (
    <div className={className}>
      {language && (
        <MEditor
          key={refreshKey}
          defaultLanguage={
            ["cpp", "ino"].includes(language) ? "arduino" : language
          }
          language={["cpp", "ino"].includes(language) ? "arduino" : language}
          value={userCode}
          theme={isDark ? "vs-dark" : "arduino-theme"}
          onChange={(value, event) => setCode(value)}
          onMount={(editor, monaco) => setMount(editor)}
          beforeMount={
            ["cpp", "ino"].includes(language)
              ? (monaco: Monaco) => editorBeforeMount(monaco)
              : undefined
          }
          loading={<LoadingScreen />}
          options={{
            minimap: { enabled: community !== "deneyap" },
          }}
        />
      )}
    </div>
  );
};
